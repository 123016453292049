import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import PageIntro from "../../components/PageIntro";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContactSection from "../../components/contactSection"
import ImageDisplay from "../../components/ImageDisplay";
import Highlight from "../../components/Highlight";
import AppearOnScroll from "../../components/AppearOnScroll"

  
  
function ServicesDisplay({ data }) {
  
      const images = data.allFile.edges

      const marketing = [
        {
            category: "Branding",
            heading: "Shape your brand.",
            id: "a2",
            text: "Inspire loyalty with a unique brand that resonates with your vision and customers.",
            extraText: "",
            keywords: [
                "Brand identity", 
                "Rebrands", 
                "Packaging", 
                "Logo design", 
                "Style guide"
            ],
            link: "/services",
            cta: "Design your vision",
        },
    ];
  
      return (
          <>
            <AppearOnScroll> 
            <ImageDisplay 
                images={ images } 
                selection={["1"]}
              />
            </AppearOnScroll>
              {/* {
                marketing.map( service => 
                  <Highlight service={ service } images={ images } id={ service.id} />
                  )
              } */}

              <ImageDisplay 
                images={ images } 
                selection={["2"]}
              />
              <ImageDisplay 
                images={ images } 
                selection={["3"]}
                constrained
              />
              <ImageDisplay 
                images={ images } 
                selection={["4"]}
              />
              <ImageDisplay 
                images={ images } 
                selection={["5"]}
                constrained
              />

          </>
      )
  }

const ProductDetailsPage = ({ data }) => {

    return (
      <Layout navBarType="white">
          <Seo title="Services" />
          <PageIntro 
            title="Indulgence"
            headline="Indulge your senses."
      
            ratio={3/4}
            />  
            <ServicesDisplay data={ data }/>
            <ContactSection />
      </Layout>
)}

export default ProductDetailsPage



export const query = graphql`
query IndulgencePageQuery {
    allFile(
        filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "indulgence"}}
        ) {
        edges {
            node {
            childImageSharp {
                gatsbyImageData
            }
            name
            }
        }
    }
}
`