import React from 'react';
import { useInView } from 'react-intersection-observer';

const AppearOnScroll = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px',
    threshold: '0',
  });

  const scrollStyle = { opacity: inView ? 1 : 0, transition: "opacity 1s" }

  return (
    <div ref={ref} style={ scrollStyle }>
      { children }
    </div>
  );
  
};

export default AppearOnScroll;